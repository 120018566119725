<template>
    <form>
        <div class="row">
            <div class="col m-a" style="height: 60px;">
                <base-radio v-model="payment" type="radio" id="paxum" name="paxum" value="paxum" class="py-a">
                    <img src="/img/paxum-logo.png" style="height: 60px">
                </base-radio>
            </div>
            <div class="col m-a">
                <base-radio v-model="payment" type="radio" id="paypal" name="paypal" value="paypal">
                    <img src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_150x38.png" style="height: 60px">
                </base-radio>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col">
                <div v-if="payment === 'paxum' || payment === 'paypal'">
                    <label class="label-header" for="email">{{getTrans('messages.enter_your')}} {{payment[0].toUpperCase() + payment.substring(1)}} {{getTrans('messages.email').toLowerCase()}}:</label>
                    <base-input type="text" id="email" v-model="email" :placeholder="payment[0].toUpperCase() + payment.substring(1) + ' email'" :error="getError('payout.email')"></base-input>
                </div>
                <div class="col-12" v-if="payment === 'wire'">
                    <div class="row" style="margin-top: 25px">
                        <div class="col-6">
                            <div class="row">
                                <div class="col-12 billing-input">
                                    <base-input type="text" id="first_name" v-model="first_name" :label="getTrans('messages.first_name')" :placeholder="getTrans('messages.your_first_name')" :error="getError('payout.first_name')">
                                    </base-input>
                                </div>
                                <div class="col-12 billing-input">
                                    <base-input type="text" id="address" v-model="address" :label="getTrans('messages.address')" :placeholder="getTrans('messages.your_address')" :error="getError('payout.address')">
                                    </base-input>
                                </div>
                                <div class="col-12 billing-input">
                                    <base-input :label="getTrans('messages.country')" :error="getError('payout.country')"></base-input>
                                    <el-select
                                        v-model="country"
                                        filterable
                                    >
                                        <el-option
                                        v-for="country in getCountries"
                                        :key="country.id"
                                        :label="country.name"
                                        :value="country.id"
                                        id="currentcountry"
                                        >
                                        </el-option>
                                    </el-select>
                                </div>
                                <div class="col-12 billing-input">
                                    <base-input id="vat" type="text" v-model="vat" :label="getTrans('messages.bank_vat')" :placeholder="getTrans('messages.your_bank_vat')" :error="getError('payout.vat')"></base-input>
                                </div>
                                <div class="col-12 billing-input">
                                    <base-input id="bank_name" type="text" v-model="bank_name" :label="getTrans('messages.bank_name')" :placeholder="getTrans('messages.bank_name')" :error="getError('payout.bank_name')"></base-input>
                                </div>
                                <div class="col-12 billing-input">
                                    <base-input id="bank_swift_code" type="text" v-model="bank_swift_code" :label="getTrans('messages.bank_swift_code')" :placeholder="getTrans('messages.bank_swift_code')" :error="getError('payout.bank_swift_code')"></base-input>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row">
                                <div class="col-12 billing-input">
                                    <base-input id="last_name" type="text" v-model="last_name" :label="getTrans('messages.last_name')" :placeholder="getTrans('messages.your_last_name')" :error="getError('payout.last_name')"></base-input>
                                </div>
                                <div class="col-12 billing-input">
                                    <base-input id="zip" type="text" v-model="zip" :label="getTrans('messages.zip')" :placeholder="getTrans('messages.local_area_number')" :error="getError('payout.zip')"></base-input>
                                </div>
                                <div class="col-12 billing-input">
                                    <base-input id="city" type="text" v-model="city" :label="getTrans('messages.city')" :placeholder="getTrans('messages.your_city')" :error="getError('payout.city')"></base-input>
                                </div>
                                <div class="col-12 billing-input">
                                    <base-input id="IBAN" type="text" v-model="iban" label="IBAN" :error="getError('payout.first_name')"></base-input>
                                </div>
                                <div class="col-12 billing-input">
                                    <base-input id="bank_routing_number" type="text" v-model="bank_routing_number" :label="getTrans('messages.bank_routing_number')" :placeholder="getTrans('messages.bank_routing_number')" :error="getError('payout.bank_routing_number')"></base-input>
                                </div>
                                <div class="col-12 billing-input">
                                    <base-input :label="getTrans('messages.bank_country')" :error="getError('payout.bank_country')"></base-input>
                                    <el-select
                                        v-model="bank_country"
                                        filterable
                                    >
                                        <el-option
                                        v-for="country in getCountries"
                                        :key="country.id"
                                        :label="country.name"
                                        :value="country.id"
                                        id="currentcountry"
                                        >
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-auto">
                <el-select
                    v-model="amount"
                    filterable
                    :placeholder="getTrans('messages.select_minimum_payout_amount')"
                >
                    <el-option
                    :label="getTrans('messages.select_minimum_payout_amount')"
                    :value="null"
                    >
                    </el-option>
                    <el-option
                        label="$50"
                        value="50"
                    >
                    </el-option>
                    <el-option
                        label="$100"
                        value="100"
                    >
                    </el-option>
                    <el-option
                        label="$250"
                        value="250"
                    >
                    </el-option>
                    <el-option
                        label="$500"
                        value="500"
                    >
                    </el-option>
                    <el-option
                        label="$1000"
                        value="1000"
                    >
                    </el-option>
                </el-select>
                <base-input :error="getError('payout.minimum_amount')"><template></template></base-input>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-auto">
                <base-button @click="save">{{getTrans('messages.save')}}</base-button>
            </div>
        </div>
    </form>
</template>
<script>
import { ElSelect, ElOption } from "element-plus";
import {mapGetters} from 'vuex'
export default {
    components: {
        ElSelect,
        ElOption,
    },
    props: {
        user: Object,
        getError: {
            type: Function,
            default: ()=> {
                
            }
        },
    },
    emits: ['save'],
    data() {
        return {
            payment: 'paxum',
            currency: 'USD',
            amount: null,
            email: '',
            first_name: '',
            last_name: '',
            address: '',
            zip: '',
            country: {id: null},
            city: '',
            vat: '',
            iban: '',
            bank_name: '',
            bank_routing_number: '',
            bank_swift_code: '',
            bank_country: {id: null},

        }
    },
    computed: {
        ...mapGetters('Country', ['getCountries']),
    },
    methods: {
        save() {
            let user =  {payout: {}};
            user.payout.method = this.payment;
            user.payout.minimum_amount = this.amount;
            user.payout.email = this.email;

            this.$emit('save', user);
        },
    },
    mounted() {
        if(this.user && this.user.payout) {
            this.payment = this.user.payout.method;
            this.amount = this.user.payout.minimum_amount;
            this.email = this.user.payout.email
        }
    }
}
</script>